    <div class="columns is-centered is-flex-tablet">
    <!-- <div class="column is-hidden-mobile">
        <img src="assets/img/agendamiento_totalpack.svg" class="margin-img img-home">
    </div> -->

   

    <div class="column is-three-fifths ">
        
        <div class="is-fullwidth ">
            <div class="box is-vcentered">
                <div class="has-text-centered  label title">
                    Agenda tu atención en sucursal
                </div>
        

                <div class="field box-size-form">
                    <div class="columms is-flex-tablet is-full">
                        <div class="column is-3 is-hidden-mobile">
                            <label class="label titleLabels">Sucursal:</label>

                        </div>
                        <label class="label is-show-mobile titleLabels">Sucursal</label>
                        <div class="control has-icons-left">
                            <div class="select is-fullwidth">
                                <select [ngModel]="oficina" (ngModelChange)="onChangeSelection($event, 'Ofi')">
          
          <option value="" *ngIf="!bOfiSel">Sucursal</option>
          <option
            *ngFor="let ofi of oficinas"
            [value]="ofi.idOfi"
            [selected]="bOfiSel"
            >{{ ofi.oficina }}</option>
        </select>
                                <span class="icon is-small is-left">
                                    <i class="fas fa-building" style="color: #c1c1c1;"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="field">
                        <div class="columms is-flex-tablet is-full">
                            <div class="column is-3 is-hidden-mobile">
                                <label class="label titleLabels">Servicios:</label>

                            </div>
                            <label class="label is-show-mobile titleLabels">Servicios:</label>
                            <div class="control has-icons-left">
                                <div class="select is-fullwidth">
                                    <select  [ngModel]="serie" (ngModelChange)="onChangeSelection($event, 'Ser')" [disabled]="!bSerie">
          >
          <option value="" *ngIf="!bSerieSel" >Agendamiento web</option>
              <option
                *ngFor="let ser of series"
                [value]="ser.idSerie"
                [selected]="bSerieSel"
                >{{ ser.serie }}</option>
        </select>
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-lightbulb" style="color: #c1c1c1;"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="field" *ngIf="bFecha"> -->
                    <div class="field">
                        <div class="columms is-flex-tablet is-full">
                            <div class="column is-3 is-hidden-mobile">
                                <label class="label titleLabels">Fecha:</label>

                            </div>
                            <label class="label is-show-mobile titleLabels">Fecha:</label>
                            <div class="control has-icons-left has-icons-right">
                                <input placeholder="dd/mm/aaaa" type="text" class="form-control" [ngModel]="fecha" (ngModelChange)="onChangeSelection($event, 'Fec')" bsDatepicker #dp="bsDatepicker" [bsConfig]="bsConfig" readonly [disabled]="!bFecha"/>
                                <span class="icon is-small is-left">
                                    <i class="fas fa-calendar-alt" style="color: #c1c1c1;"></i>
                                </span>
                            </div>
                        </div>
                        <!-- <div class="field" *ngIf="bHora"> -->
                        <div class="field" >
                            <div class="columms is-flex-tablet is-full">
                                <div class="column is-3 is-hidden-mobile">
                                    <label class="label titleLabels">Hora:</label>

                                </div>
                                <label class="label is-show-mobile titleLabels">Hora:</label>
                                <div class="control has-icons-left">
                                    <div class="select is-fullwidth">
                                        <select [ngModel]="hora" (ngModelChange)="onChangeSelection($event, 'Hor')"  [disabled]="!bHora">
          >
          <option value="">Hora</option>
          <option
            *ngFor="let hor of horas"
            [value]="hor.hora"
            [disabled]="!hor.ok"
            [ngClass]="{ horDis: !hor.ok }"
          >
            {{ hor.hora }}</option
          >
        </select>
                                        <span class="icon is-small is-left">
                                            <i class="fas fa-clock" style="color: #c1c1c1;"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="has-text-centered">
                            <!-- <div class="buttons is-centered" *ngIf="bAgendar"> -->
                            <div class="buttons is-centered">
                                <div class="control">
                                    <!-- <button class="button agendar-button" (click)="agendar()" [disabled]="!bOK"> -->
                                    <button class="button agendar-button" (click)="agendar()" [disabled]="!bAgendar"
                                    [ngClass]="{'disabled-button': !bAgendar}">
                                        Agendar hora
                                    </button>
                                    <a href="#" class="is-block back-button" (click)="inicio()">Volver</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>