<div class="columns is-centered is-flex-tablet">
    <div class="column is-three-fifths">
        <div class="is-fullwidth ">
            <div class="box is-vcentered p-3">
                <div class="field has-text-centered">
                    <img src="assets/img/check.png" class="m-2">
                    <h1 class="title1">Tu hora ha sido asignada con éxito</h1>
                    <p class="title2">Hemos enviado un comprobante a tu correo electrónico</p>
                    
                </div>
                <div class="field box-size-form">
                    <div class="container text-center">
                        <div class="row">
                          <div class="col-6 labelsLeft2">
                            <label class="labelsLeft">Sucursal</label>
                          </div>
                          <div class="col-6 labelsRight2">
                            <input class="input is-static labelsRight" type="email" value="{{ oficina }}" readonly />
                          </div>
                          <div class="col-6 labelsLeft2">
                            <label class="labelsLeft">Tipo de atención</label>
                          </div>
                          <div class="col-6 labelsRight2">
                            <input class="input is-static labelsRight" type="email" value="{{ serie }}" readonly />
                          </div>
                          <div class="col-6 labelsLeft2">
                            <label class="labelsLeft">Día</label>
                          </div>
                          <div class="col-6 labelsRight2">
                            <input class="input is-static labelsRight" type="email" value="{{ fecha | date: 'dd/MM/yyyy' }}" readonly />
                          </div>
                          <div class="col-6 labelsLeft2">
                            <label class="labelsLeft">Hora</label>
                          </div>
                          <div class="col-6 labelsRight2">
                            <input class="input is-static labelsRight" type="email" value="{{ hora }}" readonly />
                          </div>
                        </div>
                    </div>

                    <!-- <div class="columms is-flex-tablet is-full has-text-centered">
                        <div class="column title1">
                            <p>¡Te esperamos!</p>
                        </div>

                    </div> -->
                    

                    <!-- <div class="columms is-flex-tablet is-full has-text-centered">
                        <div class="column title3">
                            <p>Te recomendamos asistir a la sucursal con anticipación y anunciar tu llegada con el anfitrión</p>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-2">

                        </div>
                        <div class="col-8 title3">
                          <p>Te recomendamos asistir a la sucursal con anticipación y anunciar tu llegada con el anfitrión</p>
                        </div>
                        <div class="col-2">

                        </div>
                    </div>

                    <hr class="custom-line">

                    <div class="columms is-flex-tablet is-full">
                        <div class="field">
                            <div class="buttons is-centered">
                                <a href="#" class="is-block back-button" (click)="exit()">Volver</a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>