<!-- <nav class="navbar is-centered">
 
  <div class="navbar-brand">
    <a class="navbar-item">
    
      <p class="title is-2">Agenda Web</p>
      <p class="subtitle is-4">{{ subTitle }}</p>
    </a>
    <div class="buttons is-right is-small" *ngIf="bMostrar">
      <a class="button is-small is-rounded is-link is-light" (click)="inicio()"
        >Inicio</a
      >
    </div>
  </div>
</nav> -->


<div class="navbar is-centered clas-nav"> 
    <div class="navbar-brand">
        <img [src]="urlLogo" style="text-align: center;">
    </div>
</div>
<div class="">
    <div class=""> 

         <p class="titleUnderLogo" *ngIf="subTitle === 'Listado de reservas'">{{ subTitle }}</p>
    </div>

    
</div>

<div class="buttons is-right is-small" *ngIf="subTitle === 'Listado de reservas'">
  <a class="button is-small is-rounded is-link is-light" (click)="inicio()"
    >Inicio</a
  >
</div>



