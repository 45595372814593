<app-header></app-header>
<snackalert></snackalert>
<body [style.backgroundImage]="'url(' + urlFondo + ')'" 
style="background-size: cover; background-repeat: no-repeat; background-position: center center; height: 100vh; width: 100vw;">
  <div class="container">
    
  </div>
  <ngx-spinner type="ball-triangle-path"></ngx-spinner>
  <router-outlet></router-outlet>
</body>
<!-- <app-footer></app-footer> -->
