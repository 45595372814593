import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TitleService } from '../services/title.service';

@Component({
  selector: 'app-hor-sel',
  templateUrl: './hor-sel.component.html',
  styleUrls: ['./hor-sel.component.css'],
})
export class HorSelComponent implements OnInit {
  oficina = sessionStorage.getItem('Oficina');
  serie = sessionStorage.getItem('Serie');
  fecha = sessionStorage.getItem('fec');
  hora = sessionStorage.getItem('hor');
  constructor(private route: Router, private titleService: TitleService) {}

  ngOnInit(): void {
    this.titleService.setTitle(' ');
  }

  exit() {
    this.route.navigate(['']);
  }
}
