<div class="columns is-centered ">
    <!-- <div class="column is-hidden-mobile">
        <img src="assets/img/agendamiento_totalpack.svg" class="margin-img img-home">
    </div> -->


    <div class="column is-three-fifths">
        <div class="is-fullwidth box-container">
            <form [formGroup]="filterForm" #f="ngForm" (ngSubmit)="formSubmit(f)" class="box is-vcentered p-3">
                <div class="field has-text-centered  label title2">
                    <p>Bienvenido al agendamiento de atención</p>
                </div>
                <div class="field has-text-centered  label title">
                    <p>Ingresa tu RUT y correo electrónico para agendar tu hora online</p>
                </div>

                <div class="field box-size-form">

                       <div class="row">
                            <div class="col-1">

                            </div>
                            <div class="col-4">
                                <label class="label titleLabels">RUT:</label>
                            </div>
                            <div class="col-6">
                                <div class="control has-icons-left">
                                    <input class="input" type="text" placeholder="RUT sin puntos y con guión" [ngModel]="rut" (ngModelChange)="formateaRut($event)" [ngClass]="{
                                        'is-danger':
                                            (filterForm.controls['rut'].errors?.required ||
                                            filterForm.controls['rut'].errors?.invalidrut) &&
                                            filterForm.controls['rut'].touched,
                                        'is-success':
                                            filterForm.controls['rut'].errors == null &&
                                            filterForm.controls['rut'].touched
                                    }" formControlName="rut" />
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-user" style="color: #c9c9c9;"></i>
                                    </span>
                                    <div *ngIf=" filterForm.controls['rut'].touched && (filterForm.controls['rut'].errors?.required || filterForm.controls['rut'].errors?.invalidrut)">
                                        <p class="help is-danger">Por favor ingresa un RUT válido.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1">

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1">

                            </div>
                            <div class="col-4">
                                <label class="label titleLabels">Correo Electrónico:</label>
                            </div>
                            <div class="col-6">
                                <div class="control has-icons-left">
                                    <input class="input" type="text" placeholder="Correo Electrónico" [ngClass]="{
                                        'is-danger':
                                            (filterForm.controls['email'].errors?.required ||
                                            filterForm.controls['email'].errors?.pattern) &&
                                            filterForm.controls['email'].touched,
                                        'is-success':
                                            filterForm.controls['email'].errors == null &&
                                        filterForm.controls['email'].touched
                                    }" formControlName="email" />
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-envelope" style="color: #c9c9c9;"></i>
                                    </span>
                                    <div *ngIf=" filterForm.controls['email'].touched && filterForm.controls['email'].errors?.required || filterForm.controls['email'].errors?.pattern">
                                        <p class="help is-danger">Por favor ingresa un email válido.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1">

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-1">

                            </div>
                            <div class="col-4">
                                <label class="label titleLabels">Teléfono:</label>
                            </div>
                            <div class="col-6">
                                <div class="control control has-icons-left">
                                    <input class="input" type="text" placeholder="Teléfono" [ngClass]="{
                                        'is-danger':
                                            (filterForm.controls['telefono'].errors?.required ||
                                            filterForm.controls['telefono'].errors?.invalidPhone) &&
                                            filterForm.controls['telefono'].touched,
                                        'is-success':
                                            filterForm.controls['telefono'].errors == null &&
                                            filterForm.controls['telefono'].touched
                                    }" formControlName="telefono" />
                                    <span class="icon is-small is-left">
                                        <i class="fas fa-phone" style="color: #c9c9c9;"></i>
                                    </span>
                                    <div *ngIf="filterForm.controls['telefono'].touched && filterForm.controls['telefono'].errors?.invalidPhone">
                                        <p class="help is-danger">Por favor ingresa un teléfono válido.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-1">

                            </div>
                        </div>

                </div>


                <div class="columms is-flex is-full">
                    <div class="buttons">
                        <div class="control centered-box">
                            <button type="submit" class="button next-button" [disabled]="!filterForm.valid"
                            [ngClass]="{'disabled-button': !filterForm.valid}">
                              Continuar
                            </button>


                            <button class="button is-link is-light" [disabled]="filterForm.controls['rut'].errors?.invalidrut" 
                            [ngClass]="{'disabled-button': !filterForm.controls['rut'].valid}"
                            (click)="listar()">
                                Ver Horas
                            </button>
                        </div>
                    </div>
                </div>

                

            </form>
        </div>
    </div>
</div>