import { Component, OnInit  } from '@angular/core';
import { AppConfig } from './app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'AgendaWeb';
  subTitle = '';
  urlFondo: string; 

  constructor(private config: AppConfig) {
    this.urlFondo = this.config.get('urlFondo');
  }

  ngOnInit(): void {

    document.documentElement.style.setProperty('--bg-image', `url(${this.urlFondo})`);
  }
}
